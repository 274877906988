<template>
  <div v-if="success" class="_success">
    <div class="left">
      <img src="../../assets/img/icon/10.png" alt="" />
    </div>
    <div class="right">
      <p class="_title">邮箱验证</p>
      <p class="desc">验证成功,赶快去登陆吧!</p>
    </div>
  </div>
  <div v-else class="_success">
    <div class="left">
      <img src="../../assets/img/icon/12.png" alt="" />
    </div>
    <div class="right">
      <p class="_title">验证失败</p>
      <p class="desc">请检查邮箱连接！</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Register',
  data() {
    return {
      uid: '',
      key: '',
      success: true,
    };
  },
  watch: {},

  created() {
    this.uid = this.$route.query.uid;
    this.key = this.$route.query.key;
    this.verify();
  },
  methods: {
    verify() {
      this.$request({
        url: '/user/verify',
        method: 'post',
        data: { uid: this.uid, key: this.key },
      }).then((res) => {
        if (res.code == 200) {
          this.success = true;
          this.$message.tips('验证成功,去登录吧');
          setTimeout(() => {
            this.$router.push('/login');
          }, 2000);
        } else {
          this.success = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
._success {
  padding: 0 10px;
  padding-bottom: 200px;
  display: flex;
  justify-content: center;
  .left {
    margin-left: -20px;
    img {
      width: 100px;
      height: 100px;
    }
  }
  .right {
    ._title {
      font-size: 25px;
      font-weight: bold;
      margin-top: 10px;

      text-align: center;
    }
    .desc {
      color: orange;
      font-size: 20px;
      text-align: center;
    }
  }
}
</style>
